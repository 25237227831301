import { acceptHMRUpdate, defineStore } from 'pinia';
import { findDownloadIdInSlug } from './helper/findDownloadIdInSlug';
import { isDefined } from '~/utils/guards/isDefined';
import type { LocationQuery, RouteParams } from 'vue-router';
import type {
  SoftwareEntryModel,
  SoftwareResponseModel
} from '~/lib/DownloadService/model';
import type { SoftwareQueryModel } from '~/server/api/downloads/software/index.get';

export type DownloadStoreState = {
  query: string;
  staticFilters: Record<string, string[]>;
  filters: Record<string, string[]>;
  loading: boolean | undefined;
  activeModal: string | undefined | boolean;
  software: SoftwareEntryModel | undefined;
  softwareDownloads: SoftwareResponseModel | null;
  offset: number;
};

// The name of the filter which must be set to enable selection of other filters
const SOFTWARE_PRODUCT_FILTER_NAME = 'downloadCategory.values.label.data';

const CATEGORY_ANALYTICS_MAP: Record<string, string> = {
  [SOFTWARE_PRODUCT_FILTER_NAME]: 'software-type_filter_selection',
  'softwareVersion.data': 'software-version_filter_selection',
  'groupAssets.supportedOs.data': 'supported-os_filter_selection'
};

const DOWNLOADS_PER_PAGE = 6;

export const useDownloadStore = defineStore('softwareDownloads', () => {
  const query = ref<string>('');
  const staticFilters = ref<Record<string, string[]>>({});
  const filters = ref<Record<string, string[]>>({});
  const loading = ref<boolean | undefined>(undefined);
  const activeModal = ref<string | undefined | boolean>(false);
  const software = ref<SoftwareEntryModel | undefined>(undefined);
  const softwareDownloads = ref<SoftwareResponseModel | null>(null);
  const offset = ref<number>(0);

  const _state = {
    query,
    staticFilters,
    filters,
    loading,
    activeModal,
    software,
    softwareDownloads,
    offset
  };

  const mergedFilters = computed(() => {
    return {
      ...filters.value,
      ...staticFilters.value
    };
  });

  const hasActiveFilters = computed<boolean>(() =>
    Object.values(filters.value).some((values) => !isEmpty(values))
  );
  const haveRequiredFiltersValues = computed<boolean>(() => {
    return (
      !isEmpty(filters.value[SOFTWARE_PRODUCT_FILTER_NAME]) ||
      // or any other filter (might be set via url params or manually after
      // selecting the required parameter
      hasActiveFilters.value
    );
  });
  /**
   * A map which contains a filter name (key) and a enabled state (value)
   * for that filter.
   * Filters are only enabled if the necessary filter has a value or
   * any other filter has a value (e.g. via url parameters or manually
   * set after selecting the necessary filter)
   * "Software Product" filter must be enabled.
   */
  const filterDisabledList = computed<Record<string, boolean>>(() => {
    return Object.fromEntries(
      softwareDownloads.value?.filters.map((facet) => [
        facet.name,
        facet.name !== SOFTWARE_PRODUCT_FILTER_NAME &&
          !haveRequiredFiltersValues
      ]) ?? []
    );
  });
  /**
   * Flag if the initial view is present or not.
   */
  const isInitialView = computed<boolean>(() => {
    return (
      !hasActiveFilters.value &&
      softwareDownloads.value != null &&
      softwareDownloads.value.results.length !== 0 &&
      softwareDownloads.value.query?.length === 0
    );
  });

  const canLoadMore = computed<boolean>(() => {
    return (
      (softwareDownloads.value &&
        softwareDownloads.value?.filters.length !== 0 &&
        softwareDownloads.value?.results.length <
          softwareDownloads.value?.maxCount) ??
      false
    );
  });

  async function updateFromRouteAndFetch(
    routeQuery: LocationQuery
  ): Promise<void> {
    const routeFilters: Record<string, string[]> = {};

    for (const key in routeQuery) {
      const value = routeQuery[key];
      routeFilters[key] = ensureArray(value)
        .map((value) => value?.toString())
        .filter(isDefined);
    }
    filters.value = routeFilters;
    await fetchSoftwareDownloads();
  }

  async function handleRouteChange(params: RouteParams) {
    const { $globalPageSettings } = useNuxtApp();
    const softwareCenterSlug =
      $globalPageSettings.value?.softwareCenterPage?.metadata?.slug;
    if (!softwareCenterSlug) {
      return;
    }
    const downloadId = findDownloadIdInSlug(
      ensureArray(params.slug),
      softwareCenterSlug
    );

    if (downloadId) {
      await openModal(downloadId);
    } else {
      activeModal.value = undefined;
    }
  }

  async function openModal(downloadId: string): Promise<void> {
    const { $analytics } = useNuxtApp();
    if (softwareDownloads.value?.results) {
      softwareDownloads.value?.results.filter((softwareItem) => {
        if (downloadId === softwareItem.id) {
          software.value = softwareItem;
          return;
        }
      });
    }

    if (!software.value) {
      await fetchDownloadById(downloadId);
    }

    activeModal.value = downloadId;

    $analytics?.pushToDataLayer({
      action: 'show_download',
      category: 'downloads',
      event: 'click_Internal',
      label: software.value?.name
    });
  }

  async function fetchSoftwareDownloads(): Promise<void> {
    const { $resolvedLocale } = useNuxtApp();
    loading.value = true;
    offset.value = 0;

    // XXX: abort controller
    // XXX: error handling
    softwareDownloads.value = await $fetch<SoftwareResponseModel>(
      '/api/downloads/software',
      {
        method: 'get',
        query: {
          query: query.value,
          locale: $resolvedLocale.value!,
          filters: JSON.stringify(mergedFilters.value),
          limit: DOWNLOADS_PER_PAGE.toString()
        } satisfies SoftwareQueryModel
      }
    );

    loading.value = false;
  }

  async function fetchMoreSoftwareDownloads(): Promise<void> {
    const { $resolvedLocale } = useNuxtApp();

    offset.value = softwareDownloads.value?.results?.length ?? 0;

    // XXX: abort controller
    // XXX: error handling
    const moreDownloads = await $fetch<SoftwareResponseModel>(
      '/api/downloads/software',
      {
        method: 'get',
        query: {
          query: query.value,
          locale: $resolvedLocale.value!,
          filters: JSON.stringify(mergedFilters.value),
          limit: DOWNLOADS_PER_PAGE.toString(),
          offset: offset.value.toString()
        } satisfies SoftwareQueryModel
      }
    );
    if (softwareDownloads.value && softwareDownloads.value.results) {
      softwareDownloads.value.results = softwareDownloads.value.results.concat(
        moreDownloads.results
      );
    }
  }

  async function fetchDownloadById(id: string): Promise<void> {
    const { $resolvedLocale } = useNuxtApp();
    const logger = useLogger();

    try {
      software.value = await $fetch<SoftwareEntryModel>(
        '/api/downloads/software/byId',
        {
          method: 'get',
          query: {
            id,
            locale: $resolvedLocale.value
          }
        }
      );
    } catch (e) {
      logger.error(`could not load software download by id "${id}"`, e);
    }
  }

  function handleFormValueChange(key: string, value: string | string[]) {
    const { $analytics } = useNuxtApp();

    filters.value = {
      ...filters.value,
      [key]: ensureArray(value)
    };

    if (CATEGORY_ANALYTICS_MAP[key]) {
      $analytics?.pushToDataLayer({
        action: CATEGORY_ANALYTICS_MAP[key],
        category: 'downloads_filter_selection',
        event: 'click_Internal',
        label: value
      });
    }

    updateRoute(filters.value);
  }

  function updateRoute(filters: Record<string, string[]>) {
    const router = useRouter();

    router.push(createUrlParams(filters));
  }

  function createUrlParams(newFilters?: Record<string, string[]>) {
    const clonedFilters = _cloneFilters(newFilters ?? filters.value);

    return {
      query: clonedFilters
    };
  }
  function _cloneFilters(
    newFilters?: Record<string, string[]>
  ): Record<string, string[]> {
    return JSON.parse(JSON.stringify(newFilters ?? filters.value));
  }

  function resetFilters() {
    filters.value = {};
    updateRoute(filters.value);
  }

  function createNewUrlParamsFromFilter(key: string, valueToRemove: string) {
    const clonedFilters = _cloneFilters();
    const values = filters.value[key];
    const arrayValues = ensureArray(values).filter(isDefined).map(String);

    clonedFilters[key] = arrayValues.filter(
      (value) => value && !valueToRemove.includes(value)
    );

    return createUrlParams(clonedFilters);
  }
  function closeModal() {
    activeModal.value = false;
    const logger = useLogger();
    const router = useRouter();

    const locale = useLocale();
    const { $globalPageSettings } = useNuxtApp();
    const path = $globalPageSettings.value?.softwareCenterPage?.metadata?.slug;

    if (!path) {
      logger.error('no document center page defined - can not close modal');

      return;
    }

    setTimeout(
      () =>
        router.replace({
          path: buildUrlString(locale.value, path),
          query: filters.value
        }),
      300
    );
  }
  function afterCloseModal(): void {
    const logger = useLogger();
    const router = useRouter();

    const locale = useLocale();
    const { $globalPageSettings } = useNuxtApp();
    const path = $globalPageSettings.value?.softwareCenterPage?.metadata?.slug;

    if (!path) {
      logger.error('no software center page defined - can not close modal');

      return;
    }

    setTimeout(
      () =>
        router.replace({
          path: buildUrlString(locale.value, path),
          query: filters.value
        }),
      300
    );
  }
  function changeRoute() {
    activeModal.value = false;
    const router = useRouter();
    const locale = useLocale();
    const { $globalPageSettings } = useNuxtApp();
    const path =
      $globalPageSettings.value?.softwareCenterPage?.metadata?.slug ??
      undefined;

    router.replace({
      path: buildUrlString(locale.value, path),
      query: filters.value
    });
  }

  return {
    _state,
    query,
    staticFilters,
    filters,
    loading,
    activeModal,
    software,
    softwareDownloads,
    offset,
    hasActiveFilters,
    haveRequiredFiltersValues,
    filterDisabledList,
    isInitialView,
    canLoadMore,
    updateFromRouteAndFetch,
    handleRouteChange,
    openModal,
    fetchSoftwareDownloads,
    fetchMoreSoftwareDownloads,
    fetchDownloadById,
    handleFormValueChange,
    resetFilters,
    createNewUrlParamsFromFilter,
    closeModal,
    afterCloseModal,
    changeRoute
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useDownloadStore, import.meta.hot));
}
